/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from "../store";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: () => import('../Main.vue'),
            meta: {
                requiresAuth: true
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('../Login.vue'),
            meta: {
                requiresAuth: false
            },
        },
        {
            path: '/:pathMatch(.*)*',
            component: () => import('../404.vue'),
        },
    ],
})

router.beforeEach((to, from) => {
    if (to.meta.requiresAuth) {
        // switch to checking cookie
        const userStore = useUserStore();

        // if (!userStore.accessToken) {
        //     //return user to home view if not logged in
        //     return {
        //         path: '/login'
        //     }
        // }
    }
});

export default router;