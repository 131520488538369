/* eslint-disable */
import axios from "axios";
import { defineStore } from 'pinia'
import { db } from './auth';
import { collection, query, where, getDocs, addDoc } from "firebase/firestore";

export const useUserStore = defineStore('user', {
    state: () => ({ userData: null }),
    getters: {
        userName: function (state) {
            if (state.userData) {
                return state.userData.displayName
            }

            return null;
        },
        profilePic: function (state) {
            if (state.userData) {
                return state.userData.photoURL;
            }

            return null;
        },
        accessToken: function (state) {
            if (state.userData) {
                return state.userData.accessToken;
            }

            return null;
        },
        userId: function (state) {
            if (state.userData) {
                return state.userData.uid
            }

            return null;
        },
        subActive: function (state) {
            if (state.userData) {
                return state.userData.subActive
            }

            return false;
        }
    },
    actions: {
        registerExistingUser(user) {
            return new Promise((resolve, reject) => {
                if (!user) {
                    reject(false);
                }

                this.userData = {
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    accessToken: user.accessToken,
                    uid: user.uid,
                    email: user.email
                };

                this.checkSubscription(user.email);

                resolve(this.userData);
            });
        },
        registerUser(user) {
            return new Promise((resolve, reject) => {
                if (!user) {
                    reject(false);
                }

                this.userData = {
                    displayName: user.displayName,
                    photoURL: user.photoURL,
                    accessToken: user.accessToken,
                    uid: user.uid,
                    email: user.email
                };

                this.addDbUser(user.uid, user.displayName, user.email);

                resolve(this.userData);
            });
        },
        async addDbUser(userId, displayName, email) {
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('email', '==', email));

            try {
                const querySnapshot = await getDocs(q);
                if (querySnapshot.empty) {
                    const newUserRef = await addDoc(usersRef, {
                        'userId': userId,
                        'displayName': displayName,
                        'email': email,
                    });

                    this.getPaymentLink(email);

                    return newUserRef.id;
                } else {
                    this.checkSubscription(email);

                    return null;
                }
            } catch (error) {
                console.error('Error adding user:', error);
                throw error;
            }
        },
        checkSubscription(email) {
            try {
                axios
                    .get(`${process.env.VUE_APP_BE_URL}/check-subscription`, {
                        params: {
                            q: email,
                        },
                    })
                    .then((response) => {
                        // Handle success
                        if (response) {
                            if (response.data.data == 'active') {
                                this.userData = {
                                    subActive: true,
                                };

                                return;
                            } else {
                                this.getPaymentLink(email);
                            }
                        } else {
                            this.getPaymentLink(email);
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            } catch (error) {
                console.error("Error:", error);
                throw error;
            }
        },
        getPaymentLink(email) {
            try {
                axios
                    .get(`${process.env.VUE_APP_BE_URL}/get-payment-link`, {
                        params: {
                            q: email,
                        },
                    })
                    .then((response) => {
                        // Handle success
                        if (response.data) {
                            if (response.data.data) {
                                // window.location.href = response.data.data;
                            }
                        }
                    })
                    .catch((error) => {
                        // Handle error
                        console.error("Error:", error);
                    });
            } catch (error) {
                console.error("Error:", error);
                throw error;
            }
        },
    },
})