<template>
  <Header />
  <div
    v-if="loading"
    class="container mx-auto min-h-screen flex flex-col md:flex-row w-full items-center justify-center"
  >
    <IconSpinner />
  </div>
  <router-view v-else></router-view>
</template>

<script>
/* eslint-disable */
import { getAuth } from "firebase/auth";
import Header from "./components/Header.vue";
import IconSpinner from "./components/icons/IconSpinner";
import { useUserStore } from "./store";

export default {
  name: "App",
  components: {
    Header,
    IconSpinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    getAuth().onAuthStateChanged((user) => {
      if (user) {
        const userStore = useUserStore();
        userStore
          .registerExistingUser(user)
          .then((userData) => {
            this.$router.push("/");

            this.loading = false;
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        // No user is signed in.
        this.$router.push("/login");
        this.loading = false;
        console.log("No user is signed in");
      }
    });
  },
};
</script>
