<template>
  <nav
    class="top-0 bg-slate-50 py-3 px-3 text-black items-center relative sticky z-40 h-16 grid grid-cols-1 place-items-center"
  >
    <div class="flex flex-row items-center">
      <span
        class="underline decoration-green-500 decoration-wavy text-md font-semibold leading-6 text-gray-700"
        >Drip</span
      >
      <img
        class="h-8 w-8 md:h-12 md:w-12 mx-2 flex-none rounded-full bg-gray-50"
        src="@/assets/fancypepe.png"
        alt=""
      />
      <span
        class="underline decoration-green-500 decoration-wavy text-md font-semibold leading-6 text-gray-700"
        >Reviewer</span
      >
    </div>
  </nav>
</template>

<script>
/* eslint-disable */

export default {
  name: "Header",
  props: {
    currentRoute: null,
  },
  data() {
    return {
      displayRoute: "home",
    };
  },
  watch: {
    currentRoute(value) {
      if (value) {
        this.displayRoute = value;
      }
    },
  },
  methods: {
    goHome() {
      this.$router.push("/");
    },
    viewMenu() {
      this.$emit("viewMenu");
    },
  },
};
</script>